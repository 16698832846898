import React from "react"
import { Router } from "@reach/router"
import theme from "../gatsby-plugin-theme-ui"
import Cancel from "../components/Cancel"
import { ThemeProvider } from "theme-ui"

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Cancel path="/app/cancel/:token" />
    </Router>
  </ThemeProvider>
)

export default App
