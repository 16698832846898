import React, { useState } from "react"

import { Box } from "theme-ui"
import { Heading } from "./Heading/Heading"
import { Container, Row, Column } from "./Grid"
import { Header } from "./Header/Header"
import { Footer } from "./Footer/Footer"
import { Button } from "./Button/Button"
import { language } from "../common/utils/language"
import { Text } from "./Text/Text"

const Cancel = ({ token }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const onSubmit = () => {
    setLoading(true)
    setMessage("")

    fetch("/.netlify/functions/cancelRecurring", {
      method: "POST",
      body: JSON.stringify({
        token: token,
      }),
    })
      .then(response => response.json())
      .then(resp => {
        setLoading(false)
        if (resp.success) {
          setMessage(resp.success)
        } else {
          setMessage(resp.error)
        }
      })
      .catch(err => {
        setLoading(false)
        setMessage("Something went wrong. Please try again.")
      })
  }

  return (
    <>
      <Header />
      <Container pb={[20]}>
        <Row alignItems="center">
          <Column
            sx={{ flex: "0 0 auto", width: ["100%", "100%", "100%", "80%"] }}
          >
            <Heading as="h1" variant="hero" mb={[3]} color="textDark">
              {language.cancel.headline}
            </Heading>
          </Column>
        </Row>
        <Row
          sx={{
            justifyContent: "space-between",
            flexDirection: ["column", "column", "row"],
          }}
        >
          <Column
            sx={{
              flex: "0 0 auto",
              width: ["100%", "100%", "40%"],
              order: [0, 0, 1],
            }}
          >
            <Box
              bg="bgLight"
              p={[3]}
              sx={{
                borderRadius: "10px",
                width: "100%",
                mb: [3, 2],
                alignSelf: "flex-start",
                position: ["relative", "relative", "sticky"],
                top: [0, 0, 2],
              }}
            >
              <Heading variant="primary" mb={[2, 3]}>
                {language.cancel.formTitle}
              </Heading>
              <Text mb={[2, 3]}>
                If you have any questions about this transaction, reach out to
                our{" "}
                <a href={`mailto:${language.cancel.support_url}`}>
                  support team
                </a>{" "}
                for help.
              </Text>
              {message ? (
                <Box
                  bg="brandBlue"
                  p={[2]}
                  sx={{
                    borderRadius: "10px",
                    width: "100%",
                    mb: [3, 2],
                  }}
                >
                  <Text color="#fff">{message}</Text>
                </Box>
              ) : null}
              <Button
                isBlock
                disabled={loading}
                variant="primary.default"
                onClick={onSubmit}
              >
                {language.cancel.submit}
              </Button>
            </Box>
          </Column>
        </Row>
      </Container>
      <Footer bg="bgDark" />
    </>
  )
}

export default Cancel
